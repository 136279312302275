:root {
  --darkblue1: #11047a;
  --darkblue2: #31268c;
  --nav-width: 60px;
  --nav-mobile-width: 300px;
  --first-color: var(--darkblue1);
  --first-color-light: #AFA5D9;
  --white-color: #F7F6FB;
}

* {
  padding: 0;
  margin: 0;
  font-family: Segoe UI;
  /* box-sizing: border-box; */
}

.secondary-color {
  background-color: #f2f3f3;
}

.btns:active {
  transform: translateY(1px);
}

.card-titles {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 1rem;
}

.CardsContainer {
  display: flex;

}

.row {
  padding: 0px !important;
}

/* Pagination css */
.pagination-buttons {
  display: flex;
}

.sticky-header {
  display: flex;

}

.no-break {
  page-break-inside: avoid;
}

@media(max-width:700px) {
  .sticky-header {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }

  .responsive-input {
    width: 70vw !important;
  }

  .responsive-input-1 {
    width: 55vw !important;
  }

  .headerTitles {
    font-size: 1.7rem !important;
    text-align: center;
    word-wrap: break-word;
  }

  .responsive-flex {
    flex-direction: column;
    align-items: center;
  }
}

.circle {
  width: 20px;
  height: 2rem !important;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
}

.circle:hover {
  background-color: #f2f3f3 !important;

  /* Change the background color on hover */
}

.circle.disabled {
  opacity: 0.5;
  /* You can adjust the opacity or any other styles to visually represent it as disabled */
  cursor: not-allowed;
  /* Change the cursor to not-allowed to indicate it's disabled */
  pointer-events: none;
  /* Disable pointer events to prevent interactions */
}

.pagination-info {
  display: flex;
  align-items: center;
  margin: 0 10px;
  /* Adjust the margin as needed */
}

.pagination-info p {
  margin: 0;
}

/* ServiceStationsList Css Start */

.card-containerBUs {
  background-color: white;
  border: 1px;
  border-radius: 8px;
  /* padding: 20px; */
}

.card-container {
  background-color: white;
  border: 1px;
  border-radius: 8px;
  /* padding: 20px; */
}

.employee-table {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;
}

table {
  width: 100%;
  border: 1px solid lightgray;

}

table th {
  color: white;
  /* background-color: #0039a6; */
  background-color: #11047a;
}

th,
td {
  padding: 18px;
  text-align: center;
  color: black
}

.text-left {
  text-align: left;
}

tr:hover {
  background-color: lightgray;
  cursor: pointer;
}

th:hover {
  background-color: lightgray;
  cursor: auto;

}

@media (max-width:560px) {
  .mobile-flex {
    flex-direction: column;
  }

}

.employee-link {
  text-decoration: none;
  color: #090e13;
  cursor: pointer;
  transition: color 0.2s ease;
}

.employee-link:hover {
  color: #0056b3;
}

tbody {
  font-weight: 500;
}

.pagination-buttons {
  display: flex;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.circle:hover {
  background-color: #2980b9;
  /* Change the background color on hover */
}

.circle.disabled {
  opacity: 0.5;
  /* You can adjust the opacity or any other styles to visually represent it as disabled */
  cursor: not-allowed;
  /* Change the cursor to not-allowed to indicate it's disabled */
  pointer-events: none;
  /* Disable pointer events to prevent interactions */
}

.pagination-info {
  display: flex;
  align-items: center;
  margin: 0 10px;
  /* Adjust the margin as needed */
}

.pagination-info p {
  margin: 0;
}

/* ServiceStationsList Css End */

/* WheelsAnalysisReport Css Start */

.details-section {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
  color: #8a2be2
}

::-webkit-scrollbar {
  width: 10px;
  /* Width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Color of the scrollbar track */
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  /* Color of the scrollbar handle */
  /* border-radius: 6px; Border radius of the scrollbar handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Color of the scrollbar handle on hover */
}

/* Add this to your TestReport.css or your global stylesheet */
.highlighted {
  background-color: #007bff;
  /* Change the color as needed */
  color: #fff;
  /* Change the text color as needed */
  /* Add any other styling you want for the highlighted state */
}

.loading-spinner {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  margin-top: 15px;
  font-size: 16px;
  /* color: #007bff; */
  color: #333;
}

.chart-loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.chart-loading-text {
  margin-top: 10px;
  font-size: 14px;
  color: #333;
  /* Adjust the color based on your design */
}

/* WheelsAnalysisReport Css End */

/* ActiveVehiclesList Css Start */

.card-container-test {
  /* background-color: rgb(255, 33, 33); */
  border: 1px;
  border-radius: 8px;
}

table {
  /* max-height: 100%; */
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 15px;
  text-align: left;
}

.table-head:hover {
  background-color: white !important;
  cursor: auto;
  color: black !important;
}

.black-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* .fixed-header:hover { */
/* background-color: white; */
/* } */

tr:hover {
  background-color: var(--violet10);
  cursor: pointer;
}

option,
select {
  padding: 10px;
  margin: 0 10px;
}

option,
select:focus {
  outline: none;
}

@media (max-width: 600px) {
  table {
    font-size: 2vw;
  }
}

.employee-link {
  text-decoration: none;
  color: #090e13;
  cursor: pointer;
  transition: color 0.2s ease;
}

.employee-link:hover {
  color: #0056b3;
}

thead {
  border-bottom: 1px solid #C0CCD7;
  color: #C0CCD7;
  text-align: center;
}

thead tr {
  background-color: rgb(236, 233, 233);

}

tbody {
  font-weight: 500;
}

body {
  background-color: #f2f3f3;
  height: 100vh;
}

/* .test-page-container { */
/* background-color: red; */

/* } */

.menubar-container-height {
  height: 100vh;
}

.circle {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
}

.circle.disabled {
  opacity: 0.5;
  /* You can adjust the opacity or any other styles to visually represent it as disabled */
  cursor: not-allowed;
  /* Change the cursor to not-allowed to indicate it's disabled */
  pointer-events: none;
  /* Disable pointer events to prevent interactions */
}

.pagination-info {
  display: flex;
  align-items: center;
  margin: 0 10px;
  /* Adjust the margin as needed */
}

.pagination-info p {
  margin: 0;
}

@media (max-width:560px) {

  .mobile-flex {
    flex-direction: column;
  }

  .main-container {
    height: 100%;
  }

}

.pagination-buttons {
  display: flex;
}

/* ActiveVehiclesList Css End */

/* DevicesList Css Start */
.employee-table {
  width: 100%;
  overflow-x: auto;
  border-collapse: collapse;

}

table {
  width: 100%;
}

th,
td {
  border: 1px solid black;
  text-align: center;
}

.table-head:hover {
  background-color: white !important;
  cursor: auto;
  color: black !important;
}

.black-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

/* .fixed-header:hover {
  background-color: transparent;
} */

td tr:hover {
  /* background-color: var(--violet10); */
  cursor: pointer;
}

option,
select {
  padding: 10px;
  margin: 0 10px;
}

option,
select:focus {
  outline: none;
}

@media (max-width: 600px) {
  table {
    font-size: 14px;
  }
}

.employee-link {
  text-decoration: none;
  color: #090e13;
  cursor: pointer;
  transition: color 0.2s ease;
}

.employee-link:hover {
  color: #0056b3;
}

tbody {
  font-weight: 500;
}

.menubar-container-height {
  height: 100vh;
}

.modal-dialog-centered {
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.5);
  width: 100vw;
}

.circle {
  width: 40px;
  height: 2rem;
  /* background-color: #3498db; Default background color */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Smooth transition for background color */
}

.circle.disabled {
  opacity: 0.5;
  /* You can adjust the opacity or any other styles to visually represent it as disabled */
  cursor: not-allowed;
  /* Change the cursor to not-allowed to indicate it's disabled */
  pointer-events: none;
  /* Disable pointer events to prevent interactions */
}

.pagination-info {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.pagination-info p {
  margin: 0;
}

/* Media Queries for flex */
@media (max-width:560px) {

  .mobile-flex {
    flex-direction: column;
  }

  .display-mobile-center {
    justify-content: center;
  }

  .table-container {
    max-height: 400px;
  }

  .main-container {
    height: 100%;
  }
}

.pagination-buttons {
  display: flex;
}

/* DevicesList Css End */

/* LoginPage Css Start */

.login {
  min-height: 100vh;
}

.siginin {
  overflow-x: hidden;
  padding-left: 0;
}

.sigininbg {
  margin: 0;
  padding: 0;
  padding-left: 0 !important;
}

.bg-image {
  background-image: url('./Assets/suspension4.jpeg');
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #00102E;
  background-position: center;
  border-bottom-left-radius: 180px;
}

.eye-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
}

.login-invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: .875em;
  color: var(--bs-form-invalid-color);
}

/* LoginPage Css End */

/* Dashboard Css Start */
.headerTitles {
  font-size: 1.4vw;
}

.custom-link {
  text-decoration: none;
  display: inline-block;
}

.section-Home {
  max-width: 100vw;
  min-height: 100vh;
}

.custom-tooltip {
  border: 1px solid #ccc;
  background-color: #fff;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.custom-tooltip .label {
  margin-bottom: 5px;
}

.custom-tooltip .intro {
  margin-bottom: 5px;
}

/* Media query for smaller screens */
@media (max-width: 1500px) {

  .wheelAxisCompTableWidth {
    width: 30%;
  }

  .wheelAxisCompTable td {
    font-size: 10px !important;
  }

}

.wheelAxisCompTable td {
  font-size: 13px;
}

.reportCarImage {
  display: block;
  margin: 0 auto;
  padding: 0;
  height: auto;
  z-index: 1;
  position: relative;
}

/* Dashboard Css End */

/* Navigation Bar Css Start */

a {
  text-decoration: none
}

.header {
  position: absolute;
  width: 200px;
  right: 0;
  top: 0;
}

.header_toggle {
  color: var(--first-color);
  font-size: 1.5rem;
  cursor: pointer
}

.header_img {
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 50%;
}

.header_img img {
  width: 40px
}

.l-navbar {
  position: fixed;
  top: 0;
  left: -100%;
  height: 100vh;
  background-color: var(--darkblue1);
  transition: .5s;
  z-index: 2000;
}

.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  top: 0;
  /* Position it at the top of the viewport */
  left: 0;
  /* Position it at the left of the viewport */
  /* width: 220px; */
  /* width: 225px; */
  width: 230px;
  /* Adjust the width as needed */
}

.nav_logo,
.nav_link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 1rem;
  text-decoration: none;
  padding: .5rem 0 .5rem 1.5rem
}

.nav_logo {
  margin-bottom: 1rem
}

.nav_logo-icon {
  font-size: 1.25rem;
  color: var(--white-color)
}

.nav_logo-name {
  color: var(--white-color);
  font-weight: 700
}

.nav_link {
  position: relative;
  color: var(--first-color-light);
  margin-bottom: 1.5rem;
  transition: .3s;
  cursor: pointer;
}

.nav_link:hover {
  color: var(--white-color)
}

.nav_icon {
  font-size: 1.25rem
}

.show {
  left: 0
}

.active {
  color: var(--white-color)
}

.active::before {
  content: '';
  position: absolute;
  left: 0;
  width: 2px;
  height: 32px;
  background-color: var(--white-color)
}

.height-100 {
  height: 100vh
}

.togglebtn {
  display: none;
  position: absolute;
  right: 0px;
  z-index: 2000;
  margin: 0;
  top: 0px;
  font-size: 30px;
  padding: 20px;

}

.toggle-bars {
  background-color: white;
  z-index: 1200;
  position: fixed;
  border-radius: 10px;

}

/* Added for BrandName */
.brandName {
  /* margin-top: 20px; */
  /* margin-top: 25px; */
  /* margin-top: 40px; */
  /* margin-left: 15px; */
  margin-left: 30px;
  align-items: center;
  font-size: 12px;
}

/* Added for NavLink */
.navLink {
  margin-top: 40px;
}

/* Added for BrandImg */
.brandImg {
  width: 196px;
  height: 67px;
  /* margin-top: 50px; */
  margin-left: 2px;
}

.ouNameContainer {
  text-align: center;
  /* Center-align the text */
  color: var(--white-color);
  /* Text color */
  text-transform: capitalize;
  width: calc(100% - 180px);
  /* Adjust width dynamically based on the width of the navigation bar */
  display: inline;
  /* Display the text inline */
  align-items: center;
}

/* Navigation Bar Css End */

/* Wheel Analysis Report PDF CSS */
@media print {
  @page {
    margin: 0;
  }

  body {
    background-color: #f0f0f0;
    /* Set the background color as needed */
  }

  .page-break-after {
    page-break-after: always;
  }

  .flex-print {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

  .no-print {
    display: none !important;
  }
}

.visible {
  visibility: visible;
  height: auto;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

/* .hidden {
  visibility: hidden;
  height: 0;
  opacity: 0;
  transition: visibility 0s 0.3s, height 0.3s ease-in-out, opacity 0.3s ease-in-out;
} */

/* Media Queries  */
@media screen and (min-width: 1254px) {

  .header_img {
    width: 40px;
    height: 40px
  }

  .header_img img {
    width: 45px
  }

  .l-navbar {
    left: 0;
    /* width: 210px; */
    padding: 1rem 1rem 0 0;
    width: var(50);
  }

  /*changes done here for nav and content layout padding-left: 15.5rem;*/
  .body-pd {
    /* padding-left: 12.5rem; */
    padding-left: 15rem;
    min-height: 100vh;
    /* max-height: 100vh; */
    max-width: 100vw;
  }

  .togglebtn {
    display: none;
  }

}

@media (max-width: 1400px) {

  /* Adjusting column width for smaller screens */
  #ServiceStationDetails_screenResolution table th,
  #ServiceStationDetails_screenResolution table td,
  #TestDetails_screenResolution table th,
  #TestDetails_screenResolution table td {
    padding: 8px;
    /* Adjust cell padding */
  }

  /* wheel button */

  /* Adjusting column width for medium devices */
  #wheelButton_ScreenResolution .col-md-3 {
    width: 50% !important;
    max-width: 50% !important;
    flex: 0 0 50% !important;
  }
}

@media (max-width: 768px) {
  .table-responsive {
    overflow-x: auto;
    /* Added for horizontal scrolling */
  }
}

@media (max-width: 576px) {
  .row {
    flex-direction: column;
    /* Added for stacking tables vertically */
  }
}

@media (max-width:763px) {

  .togglebtn {
    display: block;
  }

  .l-navbar {
    width: 100vw;

  }

  .xcross {
    color: white;
  }

}

@media (max-width:767px) {

  .togglebtn {
    display: block;
  }

}

@media(max-width:500px) {

  .l-navbar {
    width: 100vw;

  }

  .nav {
    text-align: center;
  }

  .show {
    width: calc(var(--nav-mobile-width) +10px);
  }

  aside {
    flex: 0 1 200px;
  }
}

@media(max-width:481px) {

  .l-navbar {
    width: 100vw;

  }

  .responsive-input {
    width: 40vw !important;
  }

}

/* Background colour for hovering in list in rows. */
.clickable-row:hover {
  /* background-color: #e0e0e0;  Adjust the color as per your preference */
  background-color: #deebff;
}

/* Transition effect while hovering in list. */
.clickable-row {
  transition: background-color 0.3s ease;
  /* Add a smooth transition effect */
}

/* Add styles for the table headers with hover effect */
th {
  position: sticky;
  top: 0;
  white-space: nowrap;
  background-color: #f0f0f0;
  /* Set the background color for header cells */
  transition: background-color 0.3s ease;
  /*Add a smooth transition effect */
}

th:hover {
  background-color: #c0c0c0;
  /* Adjust the color as per your preference */
}

/* 
  CSS for arrow icons
.arrow-up, .arrow-down {
  display: none; /* Hide by default 
} */

.arrow-up::before {
  content: '\25B2';
  /* Upward arrow character */
  display: inline-block;
  margin-left: 5px;
}

.arrow-down::before {
  content: '\25BC';
  /* Downward arrow character */
  display: inline-block;
  margin-left: 5px;
}

/* th:hover .arrow-up,
th:hover .arrow-down {
    display: inline-block; /* Display on hover 
} */

/* AllVehiclesTestedList Media query Start */

/* @media (min-width: 1200px)  { */
/* @media (max-width: 1500px) { */
@media (max-width: 1700px) {

  .AllVehiclesDropdownOptions {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    flex-wrap: wrap;
    /* Allows items to wrap to the next line */
  }

  .AllVehiclesDropdownOptions>div {
    display: flex;
    align-items: center;
    /* margin-right: 8px; */
    margin-bottom: 10px;
    /* Space between rows */
  }

  .Select__control {
    width: auto !important;
    /* Use auto width to adapt to parent width */
    min-width: 100px;
    /* Minimum width */
  }

  .Select__menu {
    width: auto !important;
    min-width: 100px;
    z-index: 999;
  }

}

/* For tablets */
@media (max-width: 768px) {
  .col-md-12 {
    flex-direction: column;
  }

  .AllVehiclesDropdownOptions>div {
    width: 100%;
    /* Full width for each dropdown */
    margin-right: 0;
  }

  .Select__control {
    width: 100% !important;
  }

  .Select__menu {
    width: 100% !important;
  }
}

/* For mobile phones */
@media (max-width: 576px) {
  .Select__control {
    width: 100% !important;
  }

  .Select__menu {
    width: 100% !important;
  }
}

.my-modal {
  width: 40vw !important;
  /* Occupy the 90% of the screen width */
  max-width: 40vw !important;
  height: 73% !important;
}

.my-modal .modal-content {
  height: 100%;
  /* Ensure the modal content takes up the full height */
}

.my-modal .modal-body {
  overflow-y: auto;
  /* Enable vertical scrolling */
  height: calc(100% - 120px);
  /* Adjust height to fit within the modal, accounting for header and footer */
}

/* NavMenu Setting Media Query */
@media (max-width: 1300px) {
  #setting_dropDown {
    width: 200px;
    max-height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  #setting_dropDown::-webkit-scrollbar-track {
    background: transparent;
  }

  #setting_dropDown::-webkit-scrollbar {
    width: 3px;
  }

}